const image = {
  id: "image",
  title: "Image AI",
  icon: "mdi mdi-image",
  logo: "ai-image",
  brands: [
    { id: "openai", label: "OpenAI", logo: "ai-openai" },
    { id: "replicate", label: "Replicate", logo: "ai-replicate" },
    {
      id: "blackforestlabs",
      label: "Black Forest Labs",
      logo: "ai-blackforestlabs",
    },
    { id: "ideogram", label: "Ideogram", logo: "ai-ideogram" },
    { id: "fal", label: "Fal", logo: "ai-fal" },
    { id: "segmind", label: "Segmind", logo: "ai-segmind" },
  ],
  models: [
    {
      id: "openai-dalle-2",
      name: "OpenAI - DALL·E 2",
      url: "https://api.openai.com/v1/images/generations",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "openai",
      elements: [
        {
          options: [
            "256x256",
            "512x512",
            "1024x1024",
            "1024x1792",
            "1792x1024",
          ],
          default: "1024x1024",
          type: "DropDown",
          required: true,
          id: "size",
          label: "Size",
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://platform.openai.com/docs/guides/images/usage?lang=curl",
      getTemplate: (vars) => {
        return {
          model: "dall-e-2",
          ...vars,
          n: 1,
          response_format: "url",
        };
      },
      output: {
        path: "data[0].url",
        type: "JSON",
      },
    },
    {
      id: "openai-dalle-3",
      name: "OpenAI - DALL·E 3",
      url: "https://api.openai.com/v1/images/generations",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "openai",
      elements: [
        {
          options: ["1024x1024", "1024x1792", "1792x1024"],
          default: "1024x1024",
          type: "DropDown",
          required: true,
          id: "size",
          label: "Size",
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      advanced: [
        {
          options: [
            { label: "Standard", value: "standard" },
            { label: "HD", value: "hd" },
          ],
          default: "standard",
          type: "DropDown",
          required: true,
          id: "quality",
          label: "Quality",
          helper: "Number of images to generate.",
        },
        {
          options: [
            { label: "Natural", value: "natural" },
            { label: "Vivid", value: "vivid" },
          ],
          default: "natural",
          type: "DropDown",
          required: true,
          id: "style",
          label: "Style",
          helper: "Number of images to generate.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://platform.openai.com/docs/guides/images/usage?lang=curl",
      getTemplate: (vars) => {
        return {
          model: "dall-e-3",
          ...vars,
          n: 1,
          response_format: "url",
        };
      },
      output: {
        path: "data[0].url",
        type: "JSON",
      },
    },
    {
      id: "replicate-flux-dev",
      name: "FLUX 1 Dev",
      url: "https://api.replicate.com/v1/models/black-forest-labs/flux-dev/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder:
            "A detailed description of the image you want to generate",
          helper: "Prompt for generated image",
        },
        {
          options: [
            "1:1",
            "16:9",
            "21:9",
            "2:3",
            "3:2",
            "4:5",
            "5:4",
            "9:16",
            "9:21",
          ],
          default: "1:1",
          type: "DropDown",
          required: true,
          id: "aspect_ratio",
          label: "Aspect Ratio",
          helper: "Aspect ratio for the generated image",
        },
        {
          options: ["webp", "jpg", "png"],
          default: "webp",
          type: "DropDown",
          required: true,
          id: "output_format",
          label: "Output Format",
          helper: "Format of the output images",
        },
      ],
      advanced: [
        {
          default: 3.5,
          min: 0,
          max: 10,
          type: "range",
          required: true,
          id: "guidance",
          label: "Guidance",
          helper: "Guidance for generated image. Ignored for flux-schnell",
          decimals: true,
        },
        {
          default: 80,
          min: 0,
          max: 100,
          type: "range",
          required: true,
          id: "output_quality",
          label: "Output Quality",
          helper:
            "Quality when saving the output images, from 0 to 100. Not relevant for .png outputs",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Set for reproducible generation",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        input: {
          prompt: vars.prompt,
          aspect_ratio: vars.aspect_ratio,
          output_format: vars.output_format,
          guidance: vars.guidance,
          output_quality: vars.output_quality,
          seed: vars.seed,
          disable_safety_checker: vars.disable_safety_checker,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-flux-pro",
      name: "FLUX 1 Pro",
      url: "https://api.replicate.com/v1/models/black-forest-labs/flux-pro/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Text prompt for image generation",
        },
        {
          options: ["1:1", "16:9", "2:3", "3:2", "4:5", "5:4", "9:16"],
          default: "1:1",
          type: "DropDown",
          required: true,
          id: "aspect_ratio",
          label: "Aspect Ratio",
          helper: "Aspect ratio for the generated image",
        },
      ],
      advanced: [
        {
          default: 25,
          min: 1,
          max: 50,
          type: "range",
          required: true,
          id: "steps",
          label: "Steps",
          helper: "Number of diffusion steps",
        },
        {
          default: 3,
          min: 2,
          max: 5,
          type: "range",
          required: true,
          id: "guidance",
          label: "Guidance",
          helper:
            "Controls the balance between adherence to the text prompt and image quality/diversity.",
          decimals: true,
        },
        {
          default: 2,
          min: 1,
          max: 4,
          type: "range",
          required: true,
          id: "interval",
          label: "Interval",
          helper:
            "Increases the variance in possible outputs, making the model more dynamic.",
          decimals: true,
        },
        {
          default: 2,
          min: 1,
          max: 5,
          type: "range",
          required: true,
          id: "safety_tolerance",
          label: "Safety Tolerance",
          helper:
            "Safety tolerance, 1 is most strict and 5 is most permissive.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        input: {
          prompt: vars.prompt,
          steps: vars.steps,
          guidance: vars.guidance,
          interval: vars.interval,
          aspect_ratio: vars.aspect_ratio,
          safety_tolerance: vars.safety_tolerance,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-flux-schnell",
      name: "FLUX 1 Schnell",
      url: "https://api.replicate.com/v1/models/black-forest-labs/flux-schnell/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Prompt for generated image",
        },
        {
          options: [
            "1:1",
            "16:9",
            "21:9",
            "2:3",
            "3:2",
            "4:5",
            "5:4",
            "9:16",
            "9:21",
          ],
          default: "1:1",
          type: "DropDown",
          required: true,
          id: "aspect_ratio",
          label: "Aspect Ratio",
          helper: "Aspect ratio for the generated image",
        },
        {
          options: ["webp", "jpg", "png"],
          default: "webp",
          type: "DropDown",
          required: true,
          id: "output_format",
          label: "Output Format",
          helper: "Format of the output images",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Set for reproducible generation",
        },
        {
          default: 80,
          min: 0,
          max: 100,
          type: "range",
          required: true,
          id: "output_quality",
          label: "Output Quality",
          helper:
            "Quality when saving the output images, from 0 to 100. Not relevant for .png outputs",
        },
        {
          type: "DropDown",
          id: "megapixels",
          label: "Megapixels",
          description:
            "The approximate number of megapixels for the generated image",
          options: ["1", "0.25"],
          default: "1",
        },
        {
          type: "Number",
          id: "num_outputs",
          label: "Number of Outputs",
          description: "The number of output images to generate",
          default: 1,
          min: 1,
          max: 4,
        },
        {
          type: "CheckBox",
          id: "go_fast",
          label: "Go Fast",
          description: "Run faster predictions using an optimized model",
          default: true,
        },
        {
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          description: "Adjust the classifier-free guidance scale",
          default: 7.5,
          min: 1,
          max: 20,
          decimals: true,
        },
        {
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          description: "Adjust the prompt strength for inpainting",
          default: 0.8,
          min: 0,
          max: 1,
          decimals: true,
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        input: {
          prompt: vars.prompt,
          ...vars
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-flux-1.1-pro",
      name: "FLUX 1.1 Pro",
      url: "https://api.replicate.com/v1/models/black-forest-labs/flux-1.1-pro/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Prompt for generated image",
        },
        {
          options: [
            "1:1",
            "16:9",
            "21:9",
            "2:3",
            "3:2",
            "4:5",
            "5:4",
            "9:16",
            "9:21",
          ],
          default: "1:1",
          type: "DropDown",
          required: true,
          id: "aspect_ratio",
          label: "Aspect Ratio",
          helper: "Aspect ratio for the generated image",
        },
        {
          options: ["webp", "jpg", "png"],
          default: "webp",
          type: "DropDown",
          required: true,
          id: "output_format",
          label: "Output Format",
          helper: "Format of the output images",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Set for reproducible generation",
        },
        {
          default: 80,
          min: 0,
          max: 100,
          type: "range",
          required: true,
          id: "output_quality",
          label: "Output Quality",
          helper:
            "Quality when saving the output images, from 0 to 100. Not relevant for .png outputs",
        },
        {
          type: "DropDown",
          id: "megapixels",
          label: "Megapixels",
          description:
            "The approximate number of megapixels for the generated image",
          options: ["1", "0.25"],
          default: "1",
        },
        {
          type: "Number",
          id: "num_outputs",
          label: "Number of Outputs",
          description: "The number of output images to generate",
          default: 1,
          min: 1,
          max: 4,
        },
        {
          type: "CheckBox",
          id: "go_fast",
          label: "Go Fast",
          description: "Run faster predictions using an optimized model",
          default: true,
        },
        {
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          description: "Adjust the classifier-free guidance scale",
          default: 7.5,
          min: 1,
          max: 20,
          decimals: true,
        },
        {
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          description: "Adjust the prompt strength for inpainting",
          default: 0.8,
          min: 0,
          max: 1,
          decimals: true,
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        input: {
          prompt: vars.prompt,
          ...vars
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-background-removal",
      name: "Background Removal",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "file",
          required: true,
          id: "image",
          label: "Image",
          heper: "Input image",
        },
      ],
      advanced: [
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "fb8af171cfa1616ddcf1242c093f9c46bcada5ad4cf6f2fbe8b81b330ec5c003",
        input: {          
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output",
        },
        authType: "Bearer",
      },
    },    
    {
      id: "replicate-material-transfer",
      name: "Material Transfer",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Prompt for generated image",
        },
        {
          type: "file",
          required: true,
          id: "subject_image",
          label: "Subject Image",
          heper: "Subject image to transfer the material to",
        },
        {
          type: "file",
          required: true,
          id: "material_image",
          label: "Material Image",
          heper: "Material to transfer to the input image",
        },
        {
          type: "DropDown",
          required: true,
          id: "output_format",
          label: "Output Format",
          options: ["webp", "png", "jpg"],
          default: "webp",
        },
      ],
      advanced: [
        {
          type: "DropDown",
          required: true,
          id: "material_strength",
          label: "Material Strength",
          options: ["medium", "strong"],
          default: "medium",
        },
        {
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          default: 2,
          min: 1,
          max: 10,
          helper: "Control adherence to the prompt",
          decimals: true
        },
        {
          type: "Number",
          id: "steps",
          label: "Steps",
          default: 6,
          min: 6,
          max: 20,
          helper: "Number of refinement steps",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Set a seed for reproducibility. Random by default.",
        },
        {
          type: "range",
          id: "output_quality",
          label: "Output Quality",
          default: 80,
          min: 0,
          max: 100,
          helper: "Image compression quality",
        },
        {
          type: "range",
          id: "max_width",
          label: "Max Width",
          default: 1920,
          min: 256,
          max: 4096,
          helper: "Maximum image width",
        },
        {
          type: "range",
          id: "max_height",
          label: "Max Height",
          default: 1920,
          min: 256,
          max: 4096,
          helper: "Maximum image height",
        },
        {
          type: "Checkbox",
          id: "return_intermediate_images",
          label: "Return Intermediate Images",
          default: false,
          helper: "Return debugging images",
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          helper:
            "Negative prompts do not really work in SD3. Using a negative prompt will change your output in unpredictable ways.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "2f640c887323bae445f8b5154d010b2d31c336dd5f3a1d85acd5ae04d215dea0",
        input: {
          prompt: vars.prompt,
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },    
    {
      id: "replicate-style-transfer",
      name: "Style Transfer",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut riding a unicorn",
          description: "Prompt for the image",
          required: true,
          default: "An astronaut riding a unicorn",
        },
        {
          type: "DropDown",
          id: "model",
          label: "Model",
          options: ["fast", "high-quality", "realistic", "cinematic", "animated"],
          description: "Model to use for the generation",
          default: "fast",
        },
        {
          type: "file",
          id: "structure_image",
          label: "Structure Image",
          description: "An optional image to copy structure from",
          format: "uri",
        },
        {
          type: "file",
          id: "style_image",
          label: "Style Image",
          description: "Copy the style from this image",
          required: true,
          format: "uri",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          description: "Set a seed for reproducibility. Random by default.",
          default: null,
        },
        {
          type: "Number",
          id: "width",
          label: "Width",
          description: "Width of the output image (ignored if structure image given)",
          default: 1024,
        },
        {
          type: "Number",
          id: "height",
          label: "Height",
          description: "Height of the output image (ignored if structure image given)",
          default: 1024,
        },
        {
          type: "DropDown",
          id: "output_format",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          default: "webp",
        },
        {
          type: "Number",
          id: "output_quality",
          label: "Output Quality",
          description: "Quality of the output images, from 0 to 100",
          default: 80,
          min: 0,
          max: 100,
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          description: "Things you do not want to see in your image",
        },
        {
          type: "Number",
          id: "number_of_images",
          label: "Number of Images",
          description: "Number of images to generate",
          default: 1,
          min: 1,
          max: 10,
        },
        {
          type: "range",
          id: "structure_depth_strength",
          label: "Structure Depth Strength",
          description: "Strength of the depth controlnet",
          default: 1,
          min: 0,
          max: 2,
          decimals: true
        },
        {
          type: "range",
          id: "structure_denoising_strength",
          label: "Structure Denoising Strength",
          description:
            "How much of the original image (and colors) to preserve (0 is all, 1 is none)",
          default: 0.65,
          min: 0,
          max: 1,
          decimals: true
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "f1023890703bc0a5a3a2c21b5e498833be5f6ef6e70e9daf6b9b3a4fd8309cf0",
        input: {
          prompt: vars.prompt,
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-face-control",
      name: "Face Expression Editor",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      authType: "Bearer",
      method: "POST",
      elements: [
        {
          type: "file",
          id: "image",
          label: "Image",
          format: "uri",
          description: "Image of a face",
          required: true,
        },
        {
          type: "DropDown",
          id: "output_format",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          description: "Format of the output images",
          default: "webp",
        },
      ],
      advanced: [
        {
          type: "range",
          id: "aaa",
          label: "AAA",
          description: "Controls the mouth opening for 'aaa' sound",
          default: 0,
          min: -30,
          max: 120,
          decimals: true,
        },
        {
          type: "range",
          id: "eee",
          label: "EEE",
          description: "Controls the mouth shape for 'eee' sound",
          default: 0,
          min: -20,
          max: 15,
          decimals: true,
        },
        {
          type: "range",
          id: "woo",
          label: "WOO",
          description: "Controls the mouth shape for 'woo' sound",
          default: 0,
          min: -20,
          max: 15,
          decimals: true,
        },
        {
          type: "range",
          id: "wink",
          label: "Wink",
          description: "Controls the degree of one eye closing",
          default: 0,
          min: 0,
          max: 25,
        },
        {
          type: "range",
          id: "blink",
          label: "Blink",
          description: "Controls the degree of eye closure",
          default: 0,
          min: -20,
          max: 5,
        },
        {
          type: "range",
          id: "smile",
          label: "Smile",
          description: "Adjusts the degree of smiling",
          default: 0,
          min: -0.3,
          max: 1.3,
          decimals: true,
        },
        {
          type: "range",
          id: "eyebrow",
          label: "Eyebrow",
          description: "Adjusts the height and shape of the eyebrows",
          default: 0,
          min: -10,
          max: 15,
        },
        {
          type: "range",
          id: "pupil_x",
          label: "Pupil X",
          description: "Adjusts the horizontal position of the pupils",
          default: 0,
          min: -15,
          max: 15,
        },
        {
          type: "range",
          id: "pupil_y",
          label: "Pupil Y",
          description: "Adjusts the vertical position of the pupils",
          default: 0,
          min: -15,
          max: 15,
        },
        {
          type: "range",
          id: "src_ratio",
          label: "Src Ratio",
          description: "Source ratio",
          default: 1,
          min: 0,
          max: 1,
        },
        {
          type: "range",
          id: "rotate_yaw",
          label: "Rotate Yaw",
          description: "Adjusts the left and right turn of the face",
          default: 0,
          min: -20,
          max: 20,
        },
        {
          type: "range",
          id: "rotate_roll",
          label: "Rotate Roll",
          description: "Adjusts the tilt of the face to the left or right",
          default: 0,
          min: -20,
          max: 20,
        },
        {
          type: "range",
          id: "rotate_pitch",
          label: "Rotate Pitch",
          description: "Adjusts the up and down tilt of the face",
          default: 0,
          min: -20,
          max: 20,
        },
        {
          type: "range",
          id: "crop_factor",
          label: "Crop Factor",
          description: "Crop factor",
          default: 1.7,
          min: 1.5,
          max: 2.5,
          decimals: true,
        },
        {
          type: "range",
          id: "sample_ratio",
          label: "Sample Ratio",
          description: "Sample ratio",
          default: 1,
          min: -0.2,
          max: 1.2,
          decimals: true,
        },
        {
          type: "range",
          id: "output_quality",
          label: "Output Quality",
          description: "Quality of the output images, from 0 to 100",
          default: 95,
          min: 0,
          max: 100,
        },
      ],
      getTemplate: (vars) => {
        return {
          version: "bf913bc90e1c44ba288ba3942a538693b72e8cc7df576f3beebe56adc0a92b86",
          input: { ...vars },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-style-transfer",
      name: "Style Transfer",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut riding a unicorn",
          description: "Prompt for the image",
          required: true,
          default: "An astronaut riding a unicorn",
        },
        {
          type: "DropDown",
          id: "model",
          label: "Model",
          options: ["fast", "high-quality", "realistic", "cinematic", "animated"],
          description: "Model to use for the generation",
          default: "fast",
        },
        {
          type: "file",
          id: "structure_image",
          label: "Structure Image",
          description: "An optional image to copy structure from",
          format: "uri",
        },
        {
          type: "file",
          id: "style_image",
          label: "Style Image",
          description: "Copy the style from this image",
          required: true,
          format: "uri",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          description: "Set a seed for reproducibility. Random by default.",
          default: null,
        },
        {
          type: "Number",
          id: "width",
          label: "Width",
          description: "Width of the output image (ignored if structure image given)",
          default: 1024,
        },
        {
          type: "Number",
          id: "height",
          label: "Height",
          description: "Height of the output image (ignored if structure image given)",
          default: 1024,
        },
        {
          type: "DropDown",
          id: "output_format",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          default: "webp",
        },
        {
          type: "Number",
          id: "output_quality",
          label: "Output Quality",
          description: "Quality of the output images, from 0 to 100",
          default: 80,
          min: 0,
          max: 100,
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          description: "Things you do not want to see in your image",
        },
        {
          type: "Number",
          id: "number_of_images",
          label: "Number of Images",
          description: "Number of images to generate",
          default: 1,
          min: 1,
          max: 10,
        },
        {
          type: "range",
          id: "structure_depth_strength",
          label: "Structure Depth Strength",
          description: "Strength of the depth controlnet",
          default: 1,
          min: 0,
          max: 2,
          decimals: true
        },
        {
          type: "range",
          id: "structure_denoising_strength",
          label: "Structure Denoising Strength",
          description:
            "How much of the original image (and colors) to preserve (0 is all, 1 is none)",
          default: 0.65,
          min: 0,
          max: 1,
          decimals: true
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "f1023890703bc0a5a3a2c21b5e498833be5f6ef6e70e9daf6b9b3a4fd8309cf0",
        input: {
          prompt: vars.prompt,
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-cyberpunk-80",
      name: "Cyberpunk Editor",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      authType: "Bearer",
      method: "POST",
      elements: [
       
        {
          type: "TextArea",
          id: "prompt",
          label: "Prompt",
          required: true,
          description: "Prompt for generated image, using the trigger word will enhance the trained objects or styles.",
        },
        {
          type: "DropDown",
          id: "output_format",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          default: "webp",
          description: "Format of the output images.",
        },
      ],
      advanced: [       
        {
          type: "file",
          id: "image",
          label: "Image",
          format: "uri",
          description: "Input image for img2img or inpainting mode. If provided, width and height are ignored.",
        },
        {
          type: "file",
          id: "mask",
          label: "Mask",
          format: "uri",
          description: "Input mask for inpainting mode. Black areas will be preserved, white areas will be inpainted.",
        },
        {
          type: "DropDown",
          id: "aspect_ratio",
          label: "Aspect Ratio",
          options: [
            "1:1", "16:9", "21:9", "3:2", "2:3", "4:5", "5:4", "3:4", "4:3", "9:16", "9:21", "custom"
          ],
          default: "1:1",
          description: "Aspect ratio for text-to-image mode. Ignored in img2img and inpainting modes.",
        },
        {
          type: "DropDown",
          id: "model",
          label: "Model",
          options: ["dev", "schnell"],
          default: "dev",
          description: "Model to use. 'Dev' model takes more steps, 'Schnell' is faster.",
        },
        {
          type: "Number",
          id: "width",
          label: "Width",
          description: "Width of the generated image (used only in custom aspect ratio). Ignored in img2img and inpainting.",
          min: 256,
          max: 1440,
        },
        {
          type: "Number",
          id: "height",
          label: "Height",
          description: "Height of the generated image (used only in custom aspect ratio). Ignored in img2img and inpainting.",
          min: 256,
          max: 1440,
        },
        {
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          description: "Adjusts the diffusion process; lower values produce more realistic images.",
          default: 3.5,
          min: 0,
          max: 10,
          decimals: true,
        },
        {
          type: "range",
          id: "lora_scale",
          label: "LoRA Scale",
          description: "Determines how strongly the main LoRA is applied.",
          default: 1,
          min: -1,
          max: 2,
        },
        {
          type: "range",
          id: "extra_lora_scale",
          label: "Extra LoRA Scale",
          description: "Adjusts how strongly the extra LoRA is applied.",
          default: 1,
          min: -1,
          max: 2,
        },
        {
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          description: "Adjusts how much the image influences the result in img2img / inpainting mode.",
          default: 0.8,
          min: 0,
          max: 1,
          decimals: true,
        },
        {
          type: "range",
          id: "output_quality",
          label: "Output Quality",
          description: "Image output quality (0-100, not applicable for PNG).",
          default: 90,
          min: 0,
          max: 100,
        },
        {
          type: "Number",
          id: "num_outputs",
          label: "Number of Outputs",
          description: "Number of images to output.",
          default: 1,
          min: 1,
          max: 4,
        },
        {
          type: "Number",
          id: "num_inference_steps",
          label: "Inference Steps",
          description: "Number of steps for inference (more steps yield higher quality but take longer).",
          default: 28,
          min: 1,
          max: 50,
        },
        {
          type: "Input",
          id: "extra_lora",
          label: "Extra LoRA",
          description: "Combine with another LoRA model from different sources.",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          description: "Set a random seed for reproducible results.",
        },
        {
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          description: "Disable the safety checker for generated images.",
          default: false,
        },
      ],
      getTemplate: (vars) => {
        return {
          version: "5d0cefd0746b833042b384c3a310bc4d1f9d1304ec59ba93e75097d40b967180",
          input: { ...vars },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-consistent-character",
      name: "Consistent Character",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      authType: "Bearer",
      method: "POST",
      elements: [    
        {
          type: "TextArea",
          id: "prompt",
          label: "Prompt",
          default: "A headshot photo",
          description: "Describe the subject, including clothes and hairstyle for more consistency.",
          required: true,
        },
        {
          type: "file",
          id: "subject",
          label: "Subject",
          format: "uri",
          description: "An image of a person. Best images are square close-ups of the face.",
        },
        {
          type: "DropDown",
          id: "output_format",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          default: "webp",
          description: "Format of the output images.",
        },
      ],
      advanced: [     
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          description: "Things you do not want to see in your image.",
          default: "",
        },
        {
          type: "Number",
          id: "number_of_outputs",
          label: "Number Of Outputs",
          description: "The number of images to generate.",
          default: 3,
          min: 1,
          max: 20,
        },
        {
          type: "Number",
          id: "number_of_images_per_pose",
          label: "Number Of Images Per Pose",
          description: "The number of images to generate for each pose.",
          default: 1,
          min: 1,
          max: 4,
        },
        {
          type: "range",
          id: "output_quality",
          label: "Output Quality",
          description: "Quality of the output images, from 0 to 100.",
          default: 80,
          min: 0,
          max: 100,
        },
        {
          type: "CheckBox",
          id: "randomise_poses",
          label: "Randomise Poses",
          description: "Randomise the poses used.",
          default: true,
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          description: "Set a seed for reproducibility. Random by default.",
        },
        {
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          description: "Disable safety checker for generated images.",
          default: false,
        },
      ],
      getTemplate: (vars) => {
        return {
          version: "9c77a3c2f884193fcee4d89645f02a0b9def9434f9e03cb98460456b831c8772",
          input: { ...vars },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-stable-diffusion-3",
      name: "Stable Diffusion 3",
      url: "https://api.replicate.com/v1/models/stability-ai/stable-diffusion-3/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Prompt for generated image",
        },
        {
          options: [
            "1:1",
            "16:9",
            "21:9",
            "3:2",
            "2:3",
            "4:5",
            "5:4",
            "9:16",
            "9:21",
          ],
          default: "1:1",
          type: "DropDown",
          required: true,
          id: "aspect_ratio",
          label: "Aspect Ratio",
          helper:
            "The aspect ratio of your output image. This value is ignored if you are using an input image.",
        },
        {
          options: ["webp", "jpg", "png"],
          default: "webp",
          type: "DropDown",
          required: true,
          id: "output_format",
          label: "Output Format",
          helper: "Format of the output images",
        },
      ],
      advanced: [
        {
          default: 3.5,
          min: 0,
          max: 20,
          type: "range",
          required: true,
          id: "cfg",
          label: "Cfg",
          helper:
            "The guidance scale tells the model how similar the output should be to the prompt.",
          decimals: true,
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Set a seed for reproducibility. Random by default.",
        },
        {
          type: "file",
          id: "image",
          label: "Image",
          helper:
            "Input image for image to image mode. The aspect ratio of your output will match this image.",
        },
        {
          default: 28,
          min: 1,
          max: 28,
          type: "range",
          required: true,
          id: "steps",
          label: "Steps",
          helper: "Number of steps to run the sampler for.",
        },
        {
          default: 0.85,
          min: 0,
          max: 1,
          type: "range",
          required: true,
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength (or denoising strength) when using image to image. 1.0 corresponds to full destruction of information in image.",
          decimals: true,
        },
        {
          default: 90,
          min: 0,
          max: 100,
          type: "range",
          required: true,
          id: "output_quality",
          label: "Output Quality",
          helper:
            "Quality of the output images, from 0 to 100. 100 is best quality, 0 is lowest quality.",
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          helper:
            "Negative prompts do not really work in SD3. Using a negative prompt will change your output in unpredictable ways.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        input: {
          prompt: vars.prompt,
          aspect_ratio: vars.aspect_ratio,
          output_format: vars.output_format,
          cfg: vars.cfg,
          steps: vars.steps,
          image: vars.image,
          prompt_strength: vars.prompt_strength,
          output_quality: vars.output_quality,
          negative_prompt: vars.negative_prompt,
          seed: vars.seed,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-stbl-diffusion",
      name: "Stable Diffusion",
      url: "https://api.replicate.com/v1/predictions",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "replicate",
      elements: [
        {
          type: "flex",
          content: [
            {
              options: [64, 128, 192, 256, 320, 384, 448, 512, 768],
              default: 768,
              type: "DropDown",
              required: true,
              id: "width",
              label: "Width",
            },
            {
              options: [64, 128, 192, 256, 320, 384, 448, 512, 768],
              default: 768,
              type: "DropDown",
              required: true,
              id: "height",
              label: "Height",
            },
          ],
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut",
        },
      ],
      advanced: [
        {
          options: [
            "K_EULER",
            "DDIM",
            "DPMSolverMultistep",
            "K_EULER_ANCESTRAL",
            "PNDM",
            "KLMS",
          ],
          default: "K_EULER",
          type: "DropDown",
          required: true,
          id: "scheduler",
          label: "Scheduler",
        },
        {
              options: [1, 2, 3, 4],
              default: 1,
              type: "DropDown",
              min: 1,
              max: 4,
              required: true,
              id: "num_outputs",
              label: "Num Outputs",
              helper: "Number of images to generate.",
            },
            {
              type: "Number",
              id: "seed",
              label: "Seed",
            },
        {
          default: 7.5,
          min: 1,
          max: 20,
          type: "range",
          required: true,
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance",
          decimals: true,
        },
        {
          default: 50,
          min: 1,
          max: 500,
          type: "range",
          required: true,
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps",
        },
        {
          type: "InputArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          helper: "Specify things to not see in the output",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "ac732df83cea7fff18b8472768c88ad041fa750ff7682a21affe81863cbe77e4",
          input: {
            ...vars,
          },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          databinding: "",
          template: "",
          hints: {},
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "stability-ai-sdxl",
      name: "Stability Sdxl",
      url: "https://api.replicate.com/v1/predictions",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "replicate",
      elements: [
        {
          type: "flex",
          content: [
            {
              options: [
                "1:1",
                "16:9",
                "21:9",
                "3:2",
                "2:3",
                "4:5",
                "5:4",
                "9:16",
                "9:21",
              ],
              default: "1:1",
              type: "DropDown",
              required: true,
              id: "aspect_ratio",
              label: "Aspect Ratio",
              helper:
                "The aspect ratio of your output image. This value is ignored if you are using an input image.",
            },
            {
              options: ["webp", "jpg", "png"],
              default: "webp",
              type: "DropDown",
              required: true,
              id: "output_format",
              label: "Output Format",
              helper: "Format of the output images",
            },
          ],
        },
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "An astronaut riding a rainbow unicorn",
        },
      ],
      advanced: [
        {
          type: "file",
          id: "mask",
          label: "Mask",
          helper:
            "Input mask for inpaint mode. Black areas will be preserved, white areas will be inpainted.",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed.",
        },
        {
          type: "file",
          id: "image",
          label: "Image",
          helper: "Input image for img2img or inpaint mode.",
        },
        {
          default: 1024,
          type: "Number",
          id: "width",
          label: "Width",
          helper: "Width of output image.",
        },
        {
          default: 1024,
          type: "Number",
          id: "height",
          label: "Height",
          helper: "Height of output image.",
        },
        {
          options: [
            { label: "No Refiner", value: "no_refiner" },
            {
              label: "Expert Ensemble Refiner",
              value: "expert_ensemble_refiner",
            },
            { label: "Base Image Refiner", value: "base_image_refiner" },
          ],
          default: "no_refiner",
          type: "DropDown",
          id: "refine",
          label: "Refine",
          helper: "Which refine style to use.",
        },
        {
          options: [
            "DDIM",
            "DPMSolverMultistep",
            "HeunDiscrete",
            "KarrasDPM",
            "HeunDiscrete",
            "KarrasDPM",
            "K_EULER_ANCESTRAL",
            "K_EULER",
            "K_EULER",
            "PNDM",
          ],
          default: "K_EULER",
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          helper: "Scheduler to use.",
        },
        {
          default: 0.6,
          min: 0,
          max: 1,
          type: "range",
          id: "lora_scale",
          label: "Lora Scale",
          helper: "LoRA additive scale. Only applicable on trained models.",
          decimals: true,
        },
        {
          options: [1, 2, 3, 4],
          default: 1,
          type: "DropDown",
          min: 1,
          max: 4,
          required: true,
          id: "num_outputs",
          label: "Num Outputs",
          helper: "Number of images to generate.",
        },
        {
          type: "Number",
          id: "refine_steps",
          label: "Refine Steps",
          helper:
            "For base_image_refiner, the number of steps to refine, defaults to num_inference_steps.",
        },
        {
          default: 7.5,
          min: 1,
          max: 50,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance.",
          decimals: true,
        },
        {
          default: true,
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications. If you have other provisions for generating or deploying images safely, you can use this to disable watermarking.",
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "high_noise_frac",
          label: "High Noise Frac",
          helper: "For expert_ensemble_refiner, the fraction of noise to use.",
          decimals: true,
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          helper: "Input Negative Prompt.",
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image.",
          decimals: true,
        },
        {
          default: 50,
          min: 1,
          max: 500,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps.",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "39ed52f2a78e934b3ba6e2a89f5b1c712de7dfea535525255b1aa35c5565e08b",
          input: {
            ...vars,
          },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          databinding: "",
          template: "",
          hints: {},
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "tencentarc-gfpag",
      name: "GFPGAN",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "img",
          required: true,
          id: "img",
          label: "Img",
          placeholder: "Enter image URL",
          helper: "Input image URL",
        },
        {
          options: ["v1.2", "v1.3", "v1.4", "RestoreFormer"],
          default: "v1.4",
          type: "DropDown",
          required: true,
          id: "version",
          label: "Version",
          helper:
            "GFPGAN version. v1.3: better quality. v1.4: more details and better identity.",
        },
      ],
      advanced: [
        {
          default: 2,
          min: 0.1,
          max: 10,
          type: "range",
          required: true,
          id: "scale",
          label: "Scale",
          helper: "Rescaling factor",
          decimals: true,
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "0fbacf7afc6c144e5be9767cff80f25aff23e52b0708f17e20f9879b2f21516c",
        input: {
          img: vars.img,
          scale: vars.scale,
          version: vars.version,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-stable-diffusion-inpainting",
      name: "Stable Diffusion Inpainting",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "file",
          required: true,
          id: "image",
          label: "Image",
          placeholder: "Enter image URL",
          helper:
            "Input image to in-paint. Width and height should both be divisible by 8. If they're not, the image will be center cropped to the nearest width and height divisible by 8",
        },
        {
          type: "file",
          required: true,
          id: "mask",
          label: "Mask",
          placeholder: "Enter mask image URL",
          helper:
            "Black and white image to use as mask. White pixels are inpainted and black pixels are preserved.",
        },
        {
          type: "TextArea",
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Input prompt",
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder: "Enter negative prompt",
          helper:
            "The prompt or prompts not to guide the image generation. Ignored when not using guidance (i.e., ignored if `guidance_scale` is less than `1`).",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          type: "CheckBox",
          id: "invert_mask",
          label: "Invert Mask",
          helper:
            "If this is true, then black pixels are inpainted and white pixels are preserved.",
          default: 0.6,
          min: 0,
          max: 1,
          decimals: true,
        },
        {
          options: [1, 2, 3, 4],
          default: 1,
          type: "DropDown",
          min: 1,
          max: 4,
          required: true,
          id: "num_outputs",
          label: "Num Outputs",
          helper: "Number of images to generate.",
        },
        {
          type: "Number",
          id: "refine_steps",
          label: "Refine Steps",
          helper:
            "For base_image_refiner, the number of steps to refine, defaults to num_inference_steps.",
        },
        {
          default: 7.5,
          min: 1,
          max: 50,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance.",
          decimals: true,
        },
        {
          default: true,
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications. If you have other provisions for generating or deploying images safely, you can use this to disable watermarking.",
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "high_noise_frac",
          label: "High Noise Frac",
          helper: "For expert_ensemble_refiner, the fraction of noise to use.",
          decimals: true,
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          helper: "Input Negative Prompt.",
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image.",
          decimals: true,
        },
        {
          default: 50,
          min: 1,
          max: 500,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps.",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "39ed52f2a78e934b3ba6e2a89f5b1c712de7dfea535525255b1aa35c5565e08b",
          input: {
            ...vars,
          },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          databinding: "",
          template: "",
          hints: {},
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-proteus-2",
      name: "Proteus 0.2",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder:
            "black fluffy gorgeous dangerous cat animal creature, large orange eyes, big fluffy ears, piercing gaze, full moon, dark ambiance, best quality, extremely detailed",
          helper: "Input prompt",
        },
        {
          type: "file",
          id: "mask",
          label: "Mask",
          placeholder: "Enter mask image URL",
          helper:
            "Input mask for inpaint mode. Black areas will be preserved, white areas will be inpainted.",
        },
        {
          type: "file",
          id: "image",
          label: "Image",
          placeholder: "Enter image URL",
          helper: "Input image for img2img or inpaint mode",
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder: "worst quality, low quality",
          helper: "Negative Input prompt",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 1024,
          type: "Number",
          id: "width",
          label: "Width",
          helper: "Width of output image",
        },
        {
          default: 1024,
          type: "Number",
          id: "height",
          label: "Height",
          helper: "Height of output image",
        },
        {
          options: [
            "DDIM",
            "DPMSolverMultistep",
            "HeunDiscrete",
            "KarrasDPM",
            "K_EULER_ANCESTRAL",
            "K_EULER",
            "PNDM",
          ],
          default: "KarrasDPM",
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          helper: "Scheduler to use",
        },
        {
          options: [1, 2, 3, 4],
          default: 1,
          type: "DropDown",
          min: 1,
          max: 4,
          required: true,
          id: "num_outputs",
          label: "Num Outputs",
          helper: "Number of images to generate.",
        },
        {
          default: 7.5,
          min: 1,
          max: 50,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance. Recommended 7-8",
          decimals: true,
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image",
          decimals: true,
        },
        {
          default: 20,
          min: 1,
          max: 100,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper:
            "Number of denoising steps. 20 to 35 steps for more detail, 20 steps for faster results.",
        },
        {
          default: true,
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications.",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "06775cd262843edbde5abab958abdbb65a0a6b58ca301c9fd78fa55c775fc019",
        input: {
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-proteus-4",
      name: "Proteus 0.4",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder:
            "black fluffy gorgeous dangerous cat animal creature, large orange eyes, big fluffy ears, piercing gaze, full moon, dark ambiance, best quality, extremely detailed",
          helper: "Input prompt",
        },
        {
          type: "file",
          id: "mask",
          label: "Mask",
          placeholder: "Enter mask image URL",
          helper:
            "Input mask for inpaint mode. Black areas will be preserved, white areas will be inpainted.",
        },
        {
          type: "file",
          id: "image",
          label: "Image",
          placeholder: "Enter image URL",
          helper: "Input image for img2img or inpaint mode",
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder: "worst quality, low quality",
          helper: "Negative Input prompt",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 1024,
          type: "Number",
          id: "width",
          label: "Width",
          helper: "Width of output image",
        },
        {
          default: 1024,
          type: "Number",
          id: "height",
          label: "Height",
          helper: "Height of output image",
        },
        {
          options: [
            "DDIM",
            "DPMSolverMultistep",
            "HeunDiscrete",
            "KarrasDPM",
            "K_EULER_ANCESTRAL",
            "K_EULER",
            "PNDM",
          ],
          default: "KarrasDPM",
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          helper: "Scheduler to use",
        },
        {
          options: [1, 2, 3, 4],
          default: 1,
          type: "DropDown",
          min: 1,
          max: 4,
          required: true,
          id: "num_outputs",
          label: "Num Outputs",
          helper: "Number of images to generate.",
        },
        {
          default: 7.5,
          min: 1,
          max: 50,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance. Recommended 7-8",
          decimals: true,
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image",
          decimals: true,
        },
        {
          default: 20,
          min: 1,
          max: 100,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper:
            "Number of denoising steps. 20 to 35 steps for more detail, 20 steps for faster results.",
        },
        {
          default: true,
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications.",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "34a427535a3c45552b94369280b823fcd0e5c9710e97af020bf445c033d4569e",
        input: {
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-realvisxl2-lcm",
      name: "Realvisxl2",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder:
            "dark shot, front shot, closeup photo of a 25 y.o latino man, perfect eyes, natural skin, skin moles, looks at viewer, cinematic shot",
          helper: "Input prompt",
        },
        {
          type: "file",
          id: "mask",
          label: "Mask",
          placeholder: "Enter mask image URL",
          helper:
            "Input mask for inpaint mode. Black areas will be preserved, white areas will be inpainted.",
        },
        {
          type: "file",
          id: "image",
          label: "Image",
          placeholder: "Enter image URL",
          helper: "Input image for img2img or inpaint mode",
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder:
            "(worst quality, low quality, illustration, 3d, 2d, painting, cartoons, sketch), open mouth",
          helper: "Negative Input prompt",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 1024,
          type: "Number",
          id: "width",
          label: "Width",
          helper: "Width of output image",
        },
        {
          default: 1024,
          type: "Number",
          id: "height",
          label: "Height",
          helper: "Height of output image",
        },
        {
          options: [
            "DDIM",
            "DPMSolverMultistep",
            "HeunDiscrete",
            "KarrasDPM",
            "K_EULER_ANCESTRAL",
            "K_EULER",
            "PNDM",
            "LCM",
          ],
          default: "LCM",
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          helper: "Scheduler to use",
        },
        {
          options: [1, 2, 3, 4],
          default: 1,
          type: "DropDown",
          min: 1,
          max: 4,
          required: true,
          id: "num_outputs",
          label: "Num Outputs",
          helper: "Number of images to generate.",
        },
        {
          default: 2,
          min: 1,
          max: 20,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance",
          decimals: true,
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image",
          decimals: true,
        },
        {
          default: 6,
          min: 1,
          max: 20,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps",
        },
        {
          default: true,
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications.",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => ({
        version:
          "479633443fc6588e1e8ae764b79cdb3702d0c196e0cb2de6db39ce577383be77",
        input: {
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "openai-gpt-mini-vision",
      name: "GPT Vision",
      url: "https://api.openai.com/v1/chat/completions",
      type: "openai",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "user_text",
          label: "User Text",
          placeholder: "What’s in this image?",
          helper: "Enter the text prompt for the model to respond to",
        },
        {
          type: "file",
          required: true,
          id: "url",
          label: "Image",
          placeholder: "Enter the image URL",
          helper: "Enter the base64 encoded image URL",
        },
      ],
      advanced: [
        {
          default: 300,
          min: 1,
          type: "Number",
          required: false,
          id: "max_tokens",
          label: "Max Tokens",
          helper: "Maximum number of tokens to generate in the completion.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink: "https://openai.com/docs",
      getTemplate: (vars) => ({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: [
              { type: "text", text: vars.user_text || "" },
              { type: "image_url", image_url: { url: vars.url } },
            ],
          },
        ],
        max_tokens: vars.max_tokens,
      }),
      output: {
        path: "choices[0].message.content",
        type: "JSON",
      },
    },
    {
      id: "replicate-image-playground",
      name: "Playground 2.5",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder:
            "Astronaut in a jungle, cold color palette, muted colors, detailed, 8k",
          helper: "Input prompt",
        },
        {
          type: "file",
          id: "mask",
          label: "Mask",
          placeholder: "Enter mask image URL",
          helper:
            "Input mask for inpaint mode. Black areas will be preserved, white areas will be inpainted.",
        },
        {
          type: "file",
          id: "image",
          label: "Image",
          placeholder: "Enter image URL",
          helper: "Input image for img2img or inpaint mode",
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder: "ugly, deformed, noisy, blurry, distorted",
          helper: "Negative Input prompt",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "width",
          label: "Width",
          helper: "Width of output image",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "height",
          label: "Height",
          helper: "Height of output image",
        },
        {
          options: [
            "DDIM",
            "DPMSolverMultistep",
            "HeunDiscrete",
            "K_EULER_ANCESTRAL",
            "K_EULER",
            "PNDM",
            "DPM++2MKarras",
            "DPMSolver++",
          ],
          default: "DPMSolver++",
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          helper:
            "Scheduler. DPMSolver++ or DPM++2MKarras is recommended for most cases",
        },
        {
          options: [1, 2, 3, 4],
          default: 1,
          type: "DropDown",
          min: 1,
          max: 4,
          required: true,
          id: "num_outputs",
          label: "Num Outputs",
          helper: "Number of images to generate.",
        },
        {
          default: 3,
          min: 0.1,
          max: 20,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance",
          decimals: true,
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image",
          decimals: true,
        },
        {
          default: 25,
          min: 1,
          max: 60,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps",
        },
        {
          default: true,
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications.",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink: "https://replicate.com/docs",
      getTemplate: (vars) => ({
        version:
          "a45f82a1382bed5c7aeb861dac7c7d191b0fdf74d8d57c4a0e6ed7d4d0bf7d24",
        input: {
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-image-prometheus-1",
      name: "Prometheus v1",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder:
            "Astronaut in a jungle, cold color palette, muted colors, detailed, 8k",
          helper: "Input prompt",
        },
        {
          type: "file",
          id: "mask",
          label: "Mask",
          placeholder: "Enter mask image URL",
          helper:
            "Input mask for inpaint mode. Black areas will be preserved, white areas will be inpainted.",
        },
        {
          type: "file",
          id: "image",
          label: "Image",
          placeholder: "Enter image URL",
          helper: "Input image for img2img or inpaint mode",
        },
      ],
      advanced: [
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder: "ugly, deformed, noisy, blurry, distorted",
          helper: "Negative Input prompt",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "width",
          label: "Width",
          helper: "Width of output image",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "height",
          label: "Height",
          helper: "Height of output image",
        },
        {
          options: [
            "DDIM",
            "DPMSolverMultistep",
            "HeunDiscrete",
            "K_EULER_ANCESTRAL",
            "K_EULER",
            "PNDM",
            "DPM++2MKarras",
            "DPMSolver++",
          ],
          default: "DPMSolver++",
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          helper:
            "Scheduler. DPMSolver++ or DPM++2MKarras is recommended for most cases",
        },
        {
          options: [1, 2, 3, 4],
          default: 1,
          type: "DropDown",
          min: 1,
          max: 4,
          required: true,
          id: "num_outputs",
          label: "Num Outputs",
          helper: "Number of images to generate.",
        },
        {
          default: 3,
          min: 0.1,
          max: 20,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance",
          decimals: true,
        },
        {
          default: 0.8,
          min: 0,
          max: 1,
          type: "range",
          id: "prompt_strength",
          label: "Prompt Strength",
          helper:
            "Prompt strength when using img2img / inpaint. 1.0 corresponds to full destruction of information in image",
          decimals: true,
        },
        {
          default: 25,
          min: 1,
          max: 60,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps",
        },
        {
          default: true,
          type: "CheckBox",
          id: "apply_watermark",
          label: "Apply Watermark",
          helper:
            "Applies a watermark to enable determining if an image is generated in downstream applications.",
        },
        {
          default: false,
          type: "CheckBox",
          id: "disable_safety_checker",
          label: "Disable Safety Checker",
          helper:
            "Disable safety checker for generated images. This feature is only available through the API.",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink: "https://replicate.com/docs",
      getTemplate: (vars) => ({
        version:
          "a40536dd5f61e7c3c43060dff5b9b488a749dec1cb283a904dd82032fc717295",
        input: {
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-photo-anime",
      name: "Photo to Anime",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "anime",
          helper: "Input prompt",
        },
        {
          type: "file",
          required: true,
          id: "image",
          label: "Image",
          placeholder: "Enter image URL",
          helper: "Input Image",
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder: "Enter negative prompt",
          helper: "Input Negative Prompt",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "width",
          label: "Width",
          helper: "Width of text-to-image output image",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "height",
          label: "Height",
          helper: "Height of text-to-image output image",
        },
        {
          default: 0.5,
          min: 0,
          max: 1,
          type: "range",
          id: "strength",
          label: "Strength",
          helper: "The Weight of Image",
          decimals: true,
        },
        {
          options: [
            "DDIM",
            "DPMSolverMultistep",
            "HeunDiscrete",
            "KarrasDPM",
            "K_EULER_ANCESTRAL",
            "K_EULER",
            "PNDM",
          ],
          default: "K_EULER_ANCESTRAL",
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          helper: "Scheduler",
        },
        {
          default: 6,
          min: 1,
          max: 20,
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          helper: "Scale for classifier-free guidance",
          decimals: true,
        },
        {
          default: 20,
          min: 1,
          max: 100,
          type: "range",
          id: "num_inference_steps",
          label: "Num Inference Steps",
          helper: "Number of denoising steps",
        },
      ],
      examples: [
        "https://replicate.delivery/pbxt/LCI4rRkfAlaSQIkrqNQDsuCqTJ6or68EyBZEJujT5Mpx1UWx/image4.jpeg",
        "https://replicate.delivery/pbxt/JYZdeTSLTd3MHyER0NnRYvOKbZNWj1X4X5HVDsoZweotLVFTA/out-0.png",
        "https://replicate.delivery/pbxt/LCIBTjFnrwgWvla6tdHbRQVVQ85y4C7hEyCGQCloE2SqI9G0/mom_3.png",
        "https://replicate.delivery/pbxt/LGpHRnasnZpWKluDOnQjXcc9gNZsiAGfE9Y09GFtxeQ4nXETA/out-0.png",
      ],
      metadata: {
        price: 0.0034,
        info: "This model costs approximately $0.0034 to run on Replicate, or 294 runs per $1, but this varies depending on your inputs",
      },
      method: "POST",
      authType: "Bearer",
      documentationAuthLink: "https://replicate.com/docs",
      getTemplate: (vars) => ({
        version:
          "7936c014091521e64f3721090cc878ab1bceb2d5e0deecc4549092fb7f9ba753",
        input: {
          ...vars,
        },
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "black-forest-labs-1",
      name: "Flux 1",
      url: "https://api.bfl.ml/v1/image",
      type: "blackforestlabs",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Input prompt",
        },
        {
          type: "flex",
          content: [
            {
              default: 1024,
              min: 256,
              max: 1536,
              type: "Number",
              required: true,
              id: "width",
              label: "Width",
              helper: "Width of text-to-image output image",
            },
            {
              default: 1024,
              min: 256,
              max: 1536,
              type: "Number",
              required: true,
              id: "height",
              label: "Height",
              helper: "Height of text-to-image output image",
            },
          ],
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 50,
          min: 1,
          max: 100,
          type: "Number",
          required: true,
          id: "steps",
          label: "Steps",
          helper: "Number of denoising steps",
        },
        {
          default: "flux.1-pro",
          type: "Text",
          required: true,
          id: "variant",
          label: "Variant",
          helper: "The variant to use for generation",
        },
        {
          default: false,
          type: "CheckBox",
          required: false,
          id: "prompt_upsampling",
          label: "Prompt Upsampling",
          helper: "Whether to use prompt upsampling",
        },
        {
          default: 3,
          min: 1,
          max: 20,
          type: "range",
          required: true,
          id: "guidance",
          label: "Guidance",
          helper: "Scale for classifier-free guidance",
          decimals: true,
        },
        {
          default: 2,
          min: 1,
          max: 5,
          type: "range",
          required: true,
          id: "safety_tolerance",
          label: "Safety Tolerance",
          helper: "Safety tolerance level",
          decimals: true,
        },
        {
          default: 2,
          min: 1,
          max: 4,
          type: "range",
          required: true,
          id: "interval",
          label: "Interval",
          helper: "Variance in possible outputs",
          decimals: true,
        },
      ],
      examples: [
        "https://replicate.delivery/pbxt/LCI4rRkfAlaSQIkrqNQDsuCqTJ6or68EyBZEJujT5Mpx1UWx/image4.jpeg",
        "https://replicate.delivery/pbxt/JYZdeTSLTd3MHyER0NnRYvOKbZNWj1X4X5HVDsoZweotLVFTA/out-0.png",
        "https://replicate.delivery/pbxt/LCIBTjFnrwgWvla6tdHbRQVVQ85y4C7hEyCGQCloE2SqI9G0/mom_3.png",
        "https://replicate.delivery/pbxt/LGpHRnasnZpWKluDOnQjXcc9gNZsiAGfE9Y09GFtxeQ4nXETA/out-0.png",
      ],
      metadata: {
        price: 0.05,
        info: "This model costs approximately $0.0034 to run on Replicate, or 294 runs per $1, but this varies depending on your inputs",
      },
      authHeader: "x-key",
      method: "POST",
      documentationAuthLink: "https://docs.bfl.ml/",
      getTemplate: (vars) => ({
        ...vars,
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.bfl.ml/v1/get_result?id=",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "result.sample",
        },
        authHeader: "x-key",
      },
    },
    {
      id: "black-forest-labs-1-1",
      name: "Flux 1.1",
      url: "https://api.bfl.ml/v1/flux-pro-1.1",
      type: "blackforestlabs",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Enter your prompt",
          helper: "Input prompt",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "width",
          label: "Width",
          helper: "Width of text-to-image output image",
        },
        {
          default: 1024,
          min: 256,
          max: 1536,
          type: "Number",
          required: true,
          id: "height",
          label: "Height",
          helper: "Height of text-to-image output image",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper: "Random seed. Leave blank to randomize the seed",
        },
        {
          default: 50,
          min: 1,
          max: 100,
          type: "Number",
          required: true,
          id: "steps",
          label: "Steps",
          helper: "Number of denoising steps",
        },
        {
          default: "flux.1-pro",
          type: "Input",
          required: true,
          id: "variant",
          label: "Variant",
          helper: "The variant to use for generation",
        },
        {
          default: false,
          type: "CheckBox",
          required: false,
          id: "prompt_upsampling",
          label: "Prompt Upsampling",
          helper: "Whether to use prompt upsampling",
        },
        {
          default: 3,
          min: 1,
          max: 20,
          type: "range",
          required: true,
          id: "guidance",
          label: "Guidance",
          helper: "Scale for classifier-free guidance",
          decimals: true,
        },
        {
          default: 2,
          min: 1,
          max: 5,
          type: "range",
          required: true,
          id: "safety_tolerance",
          label: "Safety Tolerance",
          helper: "Safety tolerance level",
          decimals: true,
        },
        {
          default: 2,
          min: 1,
          max: 4,
          type: "range",
          required: true,
          id: "interval",
          label: "Interval",
          helper: "Variance in possible outputs",
          decimals: true,
        },
      ],
      examples: [
        "https://replicate.delivery/pbxt/LCI4rRkfAlaSQIkrqNQDsuCqTJ6or68EyBZEJujT5Mpx1UWx/image4.jpeg",
        "https://replicate.delivery/pbxt/JYZdeTSLTd3MHyER0NnRYvOKbZNWj1X4X5HVDsoZweotLVFTA/out-0.png",
        "https://replicate.delivery/pbxt/LCIBTjFnrwgWvla6tdHbRQVVQ85y4C7hEyCGQCloE2SqI9G0/mom_3.png",
        "https://replicate.delivery/pbxt/LGpHRnasnZpWKluDOnQjXcc9gNZsiAGfE9Y09GFtxeQ4nXETA/out-0.png",
      ],
      metadata: {
        price: 0.05,
        info: "This model costs approximately $0.0034 to run on Replicate, or 294 runs per $1, but this varies depending on your inputs",
      },
      authHeader: "x-key",
      method: "POST",
      documentationAuthLink: "https://docs.bfl.ml/",
      getTemplate: (vars) => ({
        ...vars,
      }),
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.bfl.ml/v1/get_result?id=",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "result.sample",
        },
        authHeader: "x-key",
      },
    },
    {
      id: "replicate-flux-img2img",
      name: "Flux -Img 2 Img",
      url: "https://api.replicate.com/v1/predictions",
      type: "replicate",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          type: "file",
          required: true,
          id: "image",
          label: "Image",
          description: "Input image",
        },
        {
          type: "TextArea",
          id: "positive_prompt",
          label: "Positive Prompt",
          description: "Prompt",
          default: "anime style",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          description: "Seed for random number generator, 0 means random",
          default: 0,
        },
        {
          type: "Number",
          id: "steps",
          label: "Steps",
          description: "Number of steps",
          default: 20,
        },
        {
          type: "Number",
          id: "denoising",
          label: "Denoising",
          description: "Denoising value",
          default: 0.75,
        },
        {
          type: "Text",
          id: "scheduler",
          label: "Scheduler",
          description: "Scheduler",
          default: "simple",
        },
        {
          type: "Text",
          id: "sampler_name",
          label: "Sampler Name",
          description: "Sampler",
          default: "euler",
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://replicate.com/docs/reference/http#authentication",
      getTemplate: (vars) => {
        return {
          version:
            "0ce45202d83c6bd379dfe58f4c0c41e6cadf93ebbd9d938cc63cc0f2fcb729a5",
          input: {
            ...vars,
          },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-flux-half-illustration",
      name: "Flux Half Illustration",
      type: "replicate",
      method: "POST",
      url: "https://api.replicate.com/v1/predictions",
      authType: "Bearer",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          id: "prompt",
          type: "TextArea",
          required: true,
          label: "Prompt",
          placeholder: "Enter your image prompt here",
          description: "Prompt for the generated image.",
        },
        {
          id: "aspect_ratio",
          type: "DropDown",
          label: "Aspect Ratio",
          options: [
            "1:1",
            "16:9",
            "21:9",
            "2:3",
            "3:2",
            "4:5",
            "5:4",
            "9:16",
            "9:21",
            "custom",
          ],
          default: "1:1",
          description: "Aspect ratio for the generated image.",
        },
        {
          id: "width",
          type: "Number",
          label: "Width",
          min: 256,
          max: 1440,
          description:
            "Width of the generated image when using custom aspect ratio.",
        },
        {
          id: "height",
          type: "Number",
          label: "Height",
          min: 256,
          max: 1440,
          description:
            "Height of the generated image when using custom aspect ratio.",
        },
      ],
      advanced: [
        {
          id: "model",
          type: "DropDown",
          label: "Model",
          options: [
            { label: "Dev", value: "dev" },
            { label: "Schnell", value: "schnell" },
          ],
          default: "dev",
          description: "Model to use for inference.",
        },
        {
          id: "guidance_scale",
          type: "Range",
          label: "Guidance Scale",
          min: 0,
          max: 10,
          default: 3.5,
          description: "Guidance scale for the diffusion process.",
        },
        {
          id: "num_inference_steps",
          type: "Number",
          label: "Inference Steps",
          min: 1,
          max: 50,
          default: 28,
          description: "Number of inference steps.",
        },
        {
          id: "lora_scale",
          type: "Range",
          label: "LoRA Scale",
          min: -1,
          max: 2,
          default: 1,
          description: "How strongly to apply LoRA.",
        },
        {
          id: "output_format",
          type: "DropDown",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          default: "webp",
          description: "Format of the output image.",
        },
        {
          id: "output_quality",
          type: "Number",
          label: "Output Quality",
          min: 0,
          max: 100,
          default: 80,
          description: "Quality of the output image.",
        },
        {
          id: "disable_safety_checker",
          type: "CheckBox",
          label: "Disable Safety Checker",
          default: false,
          description: "Disable safety checker for generated images.",
        },
        {
          id: "seed",
          type: "Number",
          label: "Seed",
          description: "Random seed for reproducible generation.",
        },
      ],
      getTemplate: (vars) => {
        return {
          version:
            "687458266007b196a490e79a77bae4b123c1792900e1cb730a51344887ad9832",
          input: { ...vars },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "output[0]",
        },
        authType: "Bearer",
      },
    },
    {
      id: "image-inpainting",
      name: "Image Inpainting",
      type: "replicate",
      method: "POST",
      url: "https://api.replicate.com/v1/predictions",
      authType: "Bearer",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          id: "image",
          type: "file",
          required: true,
          label: "Image",
          format: "uri",
          description: "Input image for inpainting",
          placeholder: "https://example.com/image.png",
        },
        {
          id: "mask",
          type: "file",
          required: true,
          label: "Mask",
          format: "uri",
          description: "Mask image",
          placeholder: "https://example.com/mask.png",
        },
        {
          id: "prompt",
          type: "TextArea",
          required: true,
          label: "Prompt",
          description: "Text prompt for inpainting",
          placeholder: "Describe what you want to inpaint",
        },
      ],
      advanced: [
        {
          id: "strength",
          type: "Range",
          label: "Strength",
          min: 0,
          max: 1,
          default: 0.85,
          description: "Strength of inpainting",
        },
        {
          id: "num_inference_steps",
          type: "Number",
          label: "Number of Inference Steps",
          min: 1,
          max: 50,
          default: 30,
          description: "Number of inference steps",
        },
        {
          id: "seed",
          type: "Number",
          label: "Seed",
          description: "Random seed. Leave blank to randomize the seed",
        },
        {
          id: "output_format",
          type: "DropDown",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          default: "webp",
          description: "Format of the output image",
        },
        {
          id: "output_quality",
          type: "Number",
          label: "Output Quality",
          min: 0,
          max: 100,
          default: 80,
          description:
            "Quality of the output image, from 0 to 100. 100 is best quality, 0 is lowest quality.",
        },
      ],
      getTemplate: (vars) => {
        return {
          version:
            "11cca3274341de7aef06f04e4dab3d651ea8ac04eff003f23603d4fdf5b56ff0",
          input: { ...vars },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        input: {
          type: "JSON",
        },
        token: "",
        output: {
          path: "output[0]",
          type: "JSON",
        },
        authType: "Bearer",
      },
    },
    {
      id: "replicate-flux-dev-realism",
      name: "Flux Dev Realism",
      type: "replicate",
      method: "POST",
      url: "https://api.replicate.com/v1/predictions",
      authType: "Bearer",
      headers: [{ key: "Content-Type", value: "application/json" }],
      elements: [
        {
          id: "prompt",
          type: "TextArea",
          required: true,
          label: "Prompt",
          description: "Enter the prompt for the image generation.",
          placeholder: "Describe the image you want to generate",
        },
        {
          id: "aspect_ratio",
          type: "DropDown",
          label: "Aspect Ratio",
          options: [
            "1:1",
            "16:9",
            "21:9",
            "2:3",
            "3:2",
            "4:5",
            "5:4",
            "9:16",
            "9:21",
          ],
          default: "1:1",
          description: "Select the aspect ratio for the image.",
        },
        {
          id: "num_outputs",
          type: "Number",
          label: "Number of Outputs",
          min: 1,
          max: 4,
          default: 1,
          description: "Specify the number of images to generate.",
        },
      ],
      advanced: [
        {
          id: "guidance",
          type: "Range",
          label: "Guidance",
          min: 0,
          max: 10,
          default: 3.5,
          description: "Guidance scale for the generated image.",
        },
        {
          id: "lora_strength",
          type: "Range",
          label: "LoRA Strength",
          min: 0,
          max: 2,
          default: 0.8,
          description: "Strength of flux-realism LoRA. 0 disables it.",
        },
        {
          id: "num_inference_steps",
          type: "Number",
          label: "Number of Inference Steps",
          min: 1,
          max: 50,
          default: 30,
          description: "Number of denoising steps. Recommended range is 28-50.",
        },
        {
          id: "output_format",
          type: "DropDown",
          label: "Output Format",
          options: ["webp", "jpg", "png"],
          default: "webp",
          description: "Format of the output images.",
        },
        {
          id: "output_quality",
          type: "Number",
          label: "Output Quality",
          min: 0,
          max: 100,
          default: 80,
          description: "Quality of the output images. 100 is best quality.",
        },
        {
          id: "seed",
          type: "Number",
          label: "Seed",
          description: "Set a seed for reproducibility. Random by default.",
        },
      ],
      getTemplate: (vars) => {
        return {
          version:
            "39b3434f194f87a900d1bc2b6d4b983e90f0dde1d5022c27b52c143d670758fa",
          input: { ...vars },
        };
      },
      output: {
        path: "id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://api.replicate.com/v1/predictions/",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        input: {
          type: "JSON",
        },
        token: "",
        output: {
          path: "output[0]",
          type: "JSON",
        },
        authType: "Bearer",
      },
    },
    {
      id: "ideogram-image-generator",
      name: "Ideogram",
      url: "https://api.ideogram.ai/generate",
      headers: [{ key: "Content-Type", value: "multipart/form-data" }],
      type: "ideogram",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
        },
        {
          type: "DropDown",
          id: "aspect_ratio",
          label: "Aspect Ratio",
          options: [
            { label: "10:16", value: "ASPECT_10_16" },
            { label: "16:10", value: "ASPECT_16_10" },
            { label: "9:16", value: "ASPECT_9_16" },
            { label: "16:9", value: "ASPECT_16_9" },
            { label: "3:2", value: "ASPECT_3_2" },
            { label: "2:3", value: "ASPECT_2_3" },
            { label: "4:3", value: "ASPECT_4_3" },
            { label: "3:4", value: "ASPECT_3_4" },
            { label: "1:1", value: "ASPECT_1_1" },
            { label: "1:3", value: "ASPECT_1_3" },
            { label: "3:1", value: "ASPECT_3_1" },
          ],
          default: "ASPECT_10_16",
        },
      ],
      advanced: [
        {
          options: [
            { label: "General", value: "GENERAL" },
            { label: "Realistic", value: "REALISTIC" },
            { label: "Design", value: "DESIGN" },
            { label: "Anime", value: "ANIME" },
            { label: "Render 3D", value: "RENDER_3D" },
          ],
          default: "General",
          type: "DropDown",
          id: "style_type",
          label: "Style",
          helper: "Choose a style for the image",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          min: 0,
          max: 2147483647,
          default: null,
        },
        {
          type: "DropDown",
          required: false,
          id: "magic_prompt_option",
          label: "Magic Prompt Option",
          options: ["AUTO", "ON", "OFF"],
          default: "AUTO",
        },
        {
          type: "DropDown",
          required: false,
          id: "model",
          label: "Model",
          options: [
            { label: "V2", value: "V_2" },
            { label: "V2 Turbo", value: "V_2_TURBO" },
          ],
          default: "V_2",
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder:
            "Description of what to exclude from an image. Descriptions in the prompt take precedence to descriptions in the negative prompt.",
        },
      ],
      method: "POST",
      authHeader: "Api-Key",
      documentationAuthLink:
        "https://api-docs.ideogram.ai/reference/post_generate_image",
      getTemplate: (vars) => {
        return {
          image_request: {
            ...vars,
          },
        };
      },
      output: {
        path: "data[0].url",
        type: "JSON",
      },
    },
    {
      id: "ideogram-image-to-image-generator",
      name: "Ideogram Remix",
      url: "https://api.ideogram.ai/remix",
      headers: [{ key: "Content-Type", value: "multipart/form-data" }],
      type: "ideogram",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
        },
        {
          type: "file",
          required: true,
          id: "image_file",
          label: "Image File",
          helper: "Upload an image file (JPEG, WEBP, PNG only)",
        },
        {
          type: "Number",
          id: "image_weight",
          label: "Image Weight",
          min: 1,
          max: 100,
          default: 50,
          required: true,
        },
        {
          type: "DropDown",
          id: "aspect_ratio",
          label: "Aspect Ratio",
          options: [
            { label: "10:16", value: "ASPECT_10_16" },
            { label: "16:10", value: "ASPECT_16_10" },
            { label: "9:16", value: "ASPECT_9_16" },
            { label: "16:9", value: "ASPECT_16_9" },
            { label: "3:2", value: "ASPECT_3_2" },
            { label: "2:3", value: "ASPECT_2_3" },
            { label: "4:3", value: "ASPECT_4_3" },
            { label: "3:4", value: "ASPECT_3_4" },
            { label: "1:1", value: "ASPECT_1_1" },
            { label: "1:3", value: "ASPECT_1_3" },
            { label: "3:1", value: "ASPECT_3_1" },
          ],
          default: "ASPECT_10_16",
        },
      ],
      advanced: [
        {
          options: [
            { label: "General", value: "GENERAL" },
            { label: "Realistic", value: "REALISTIC" },
            { label: "Design", value: "DESIGN" },
            { label: "Anime", value: "ANIME" },
            { label: "Render 3D", value: "RENDER_3D" },
          ],
          default: "General",
          type: "DropDown",
          id: "style_type",
          label: "Style",
          helper: "Choose a style for the image",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          min: 0,
          max: 2147483647,
          default: null,
        },
        {
          type: "DropDown",
          required: false,
          id: "magic_prompt_option",
          label: "Magic Prompt Option",
          options: ["AUTO", "ON", "OFF"],
          default: "AUTO",
        },
        {
          type: "DropDown",
          required: false,
          id: "model",
          label: "Model",
          options: [
            { label: "V2", value: "V_2" },
            { label: "V2 Turbo", value: "V_2_TURBO" },
          ],
          default: "V_2",
        },
        {
          type: "TextArea",
          id: "negative_prompt",
          label: "Negative Prompt",
          placeholder:
            "Description of what to exclude from an image. Descriptions in the prompt take precedence to descriptions in the negative prompt.",
        },
      ],
      method: "POST",
      authHeader: "Api-Key",
      documentationAuthLink:
        "https://api-docs.ideogram.ai/reference/post_generate_image",
      input: {
        type: "FORM",
      },
      getTemplate: (vars) => {
        return {
          image_request: {
            image_weight: vars.image_weight,
            negative_prompt: vars.negative_prompt,
            style_type: vars.style_type,
            prompt: vars.prompt,
            aspect_ratio: vars.aspect_ratio,
            magic_prompt_option: vars.magic_prompt_option,
          },
          image_file: vars.image_file,
        };
      },
      output: {
        path: "data[0].url",
        type: "JSON",
      },
    },
    {
      id: "fal-image-to-image",
      name: "Image to Image",
      url: "https://fal.run/fal-ai/flux/dev/image-to-image",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "fal",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
        },
        {
          type: "file",
          required: true,
          id: "image_url",
          label: "Image URL",
          helper: "Upload an image file (JPEG, WEBP, PNG only)",
        },
      ],
      advanced: [
        {
          type: "range",
          id: "strength",
          label: "Strength",
          min: 0.0,
          max: 1.0,
          default: 0.95,
          decimals: true,
          helper:
            "Higher values produce stronger influence from the initial image",
        },
        {
          type: "DropDown",
          required: false,
          id: "image_size",
          label: "Image Size",
          options: [
            "square_hd",
            "square",
            "portrait_4_3",
            "portrait_16_9",
            "landscape_4_3",
            "landscape_16_9",
          ],
          default: "landscape_4_3",
        },
        {
          type: "Number",
          id: "num_inference_steps",
          label: "Number of Inference Steps",
          default: 40,
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          helper:
            "Use the same seed for consistent outputs with the same prompt",
        },
        {
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          min: 0.0,
          max: 10.0,
          default: 3.5,
          decimals: true,
          helper: "Determines how closely the model sticks to the prompt",
        },
        {
          type: "CheckBox",
          id: "sync_mode",
          label: "Sync Mode",
          default: false,
          helper:
            "Wait for image generation and upload before returning the response",
        },
        {
          type: "Number",
          id: "num_images",
          label: "Number of Images",
          default: 1,
          helper: "Number of images to generate",
        },
        {
          type: "CheckBox",
          id: "enable_safety_checker",
          label: "Enable Safety Checker",
          default: true,
          helper: "Enable the safety checker for image generation",
        },
      ],
      method: "POST",
      authType: "Key",
      documentationAuthLink:
        "https://fal.ai/models/fal-ai/flux/dev/image-to-image/api",
      getTemplate: (vars) => {
        return {
          ...vars,
        };
      },
      output: {
        path: "images[0].url",
        type: "JSON",
      },
    },
    {
      id: "runware-image-to-image",
      name: "Image to Image",
      url: "https://api.runware.ai/image-inference",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "runware",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "positivePrompt",
          label: "Positive Prompt",
          placeholder: "Describe the image you want to generate",
        },
        {
          type: "File",
          required: true,
          id: "seedImage",
          label: "Seed Image",
          helper: "Upload a seed image (JPEG, WEBP, PNG only)",
        },
      ],
      advanced: [
        {
          type: "range",
          id: "strength",
          label: "Strength",
          min: 0.0,
          max: 1.0,
          default: 0.8,
          decimals: true,
        },
        {
          type: "DropDown",
          required: false,
          id: "outputType",
          label: "Output Type",
          options: ["base64Data", "dataURI", "URL"],
          default: "URL",
        },
        {
          type: "DropDown",
          required: false,
          id: "outputFormat",
          label: "Output Format",
          options: ["JPG", "PNG", "WEBP"],
          default: "JPG",
        },
        {
          type: "Number",
          id: "height",
          label: "Height",
          min: 512,
          max: 2048,
          default: 1024,
        },
        {
          type: "Number",
          id: "width",
          label: "Width",
          min: 512,
          max: 2048,
          default: 1024,
        },
        {
          type: "range",
          id: "CFGScale",
          label: "CFG Scale",
          min: 0.0,
          max: 30.0,
          default: 7.0,
          decimals: true,
        },
        {
          type: "CheckBox",
          id: "checkNSFW",
          label: "Check NSFW",
          default: false,
        },
      ],
      method: "POST",
      authType: "Bearer",
      documentationAuthLink:
        "https://docs.runware.ai/en/image-inference#image-to-image",
      getTemplate: (vars) => {
        return {
          taskType: "imageInference",
          taskUUID: "unique-task-id", // Replace with UUID v4 string
          positivePrompt: vars.positivePrompt,
          seedImage: vars.seedImage,
          model: vars.model,
          height: vars.height,
          width: vars.width,
          strength: vars.strength,
          outputType: vars.outputType,
          outputFormat: vars.outputFormat,
          CFGScale: vars.CFGScale,
          checkNSFW: vars.checkNSFW,
        };
      },
      output: {
        path: "data[0].imageURL",
        type: "JSON",
      },
    },
    {
      id: "segmind-img2img",
      name: "Segmind Image to Image",
      url: "https://api.segmind.com/v1/flux-img2img",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "segmind",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image style, e.g., 'anime style'",
        },
        {
          type: "file",
          required: true,
          id: "image",
          label: "Image URL",
          helper: "Upload an image to use as the base",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "steps",
          label: "Steps",
          default: 20,
          helper: "The number of inference steps",
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
          default: 46588,
          helper: "Seed for reproducibility",
        },
        {
          type: "range",
          id: "denoise",
          label: "Denoise",
          min: 0.0,
          max: 1.0,
          default: 0.75,
          decimals: true,
          helper: "The amount of noise to remove from the image",
        },
        {
          type: "DropDown",
          id: "scheduler",
          label: "Scheduler",
          options: ["simple", "ddim", "pndm"],
          default: "simple",
        },
        {
          type: "DropDown",
          id: "sampler_name",
          label: "Sampler",
          options: ["euler", "lms", "ddim"],
          default: "euler",
        },
        {
          type: "CheckBox",
          id: "base64",
          label: "Return Base64",
          default: false,
          helper: "Return image as base64 encoded string",
        },
      ],
      method: "POST",
      documentationAuthLink: "https://docs.segmind.com/",
      authHeader: "x-api-key",
      getTemplate: (vars) => {
        return {
          prompt: vars.prompt,
          image: vars.image,
          steps: vars.steps,
          seed: vars.seed,
          denoise: vars.denoise,
          scheduler: vars.scheduler,
          sampler_name: vars.sampler_name,
          base64: vars.base64,
        };
      },
      output: {
        path: "",
        type: "IMAGE",
      },
    },
    {
      id: "fal-flux-lora",
      name: "Flux LoRA Image",
      url: "https://fal.run/fal-ai/flux-lora/image-to-image",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "fal",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
        },
        {
          type: "file",
          required: true,
          id: "image_url",
          label: "Image URL",
          helper: "Upload an image to use as the base",
        },
        {
          type: "DropDown",
          required: false,
          id: "image_size",
          label: "Image Size",
          options: [
            { value: "square_hd", label: "Square HD" },
            { value: "square", label: "Square" },
            { value: "portrait_4_3", label: "Portrait 4:3" },
            { value: "portrait_16_9", label: "Portrait 16:9" },
            { value: "landscape_4_3", label: "Landscape 4:3" },
            { value: "landscape_16_9", label: "Landscape 16:9" },
          ],
          default: "landscape_4_3",
        },
      ],
      advanced: [
        {
          type: "range",
          id: "strength",
          label: "Strength",
          min: 0.0,
          max: 1.0,
          default: 0.85,
          decimals: true,
        },
        {
          type: "Input",
          id: "path",
          label: "LoRAs",
          helper: "Add LoRAs to use for the image generation",
        },
        {
          type: "range",
          id: "scale",
          label: "Scale",
          decimals: true,
          default: 1,
          helper:
            "The scale of the LoRA weight. This is used to scale the LoRA weight before merging it with the base model. Default value: 1",
          min: 0.0,
          max: 4.0,
        },
        {
          type: "Number",
          id: "num_inference_steps",
          label: "Number of Inference Steps",
          default: 28,
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
        },
        {
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          min: 0.0,
          max: 10.0,
          default: 3.5,
          decimals: true,
        },
        {
          type: "CheckBox",
          id: "sync_mode",
          label: "Sync Mode",
          default: false,
        },
        {
          type: "Number",
          id: "num_images",
          label: "Number of Images",
          default: 1,
        },
        {
          type: "CheckBox",
          id: "enable_safety_checker",
          label: "Enable Safety Checker",
          default: true,
        },
        {
          type: "DropDown",
          id: "output_format",
          label: "Output Format",
          options: [
            { value: "jpeg", label: "JPEG" },
            { value: "png", label: "PNG" },
          ],
          default: "jpeg",
        },
      ],
      method: "POST",
      authType: "Key",
      documentationAuthLink: "https://fal.ai/models/fal-ai/flux-lora/api",
      getTemplate: (vars) => {
        return {
          prompt: vars.prompt,
          image_size: vars.image_size,
          num_inference_steps: vars.num_inference_steps,
          seed: vars.seed,
          loras: [
            {
              path: vars.path,
              scale: vars.scale,
            },
          ],
          guidance_scale: vars.guidance_scale,
          sync_mode: vars.sync_mode,
          num_images: vars.num_images,
          enable_safety_checker: vars.enable_safety_checker,
          output_format: vars.output_format,
          image_url: vars.image_url,
        };
      },
      getQuantity(vars) {
        return (vars && vars.num_images) || 1;
      },
      output: {
        path: "images",
        type: "JSON",
      },
    },
    {
      id: "fal-flux-lora-prompt",
      name: "Flux LoRA",
      url: "https://queue.fal.run/fal-ai/flux-lora",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "fal",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
        },
        {
          type: "DropDown",
          required: false,
          id: "image_size",
          label: "Image Size",
          options: [
            { value: "square_hd", label: "Square HD" },
            { value: "square", label: "Square" },
            { value: "portrait_4_3", label: "Portrait 4:3" },
            { value: "portrait_16_9", label: "Portrait 16:9" },
            { value: "landscape_4_3", label: "Landscape 4:3" },
            { value: "landscape_16_9", label: "Landscape 16:9" },
          ],
          default: "landscape_4_3",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "num_inference_steps",
          label: "Number of Inference Steps",
          default: 28,
        },
        {
          type: "Number",
          id: "seed",
          label: "Seed",
        },
        {
          type: "Input",
          id: "path",
          label: "LoRAs",
          helper: "Add LoRAs to use for the image generation",
        },
        {
          type: "range",
          id: "scale",
          label: "LoRA Scale",
          description: "Determines how strongly the main LoRA is applied.",
          default: 1,
          min: 0,
          max: 4,
          decimals: true
        },
        {
          type: "range",
          id: "guidance_scale",
          label: "Guidance Scale",
          min: 0.0,
          max: 10.0,
          default: 3.5,
          decimals: true,
        },
        {
          type: "CheckBox",
          id: "sync_mode",
          label: "Sync Mode",
          default: false,
        },
        {
          type: "Number",
          id: "num_images",
          label: "Number of Images",
          default: 1,
        },
        {
          type: "CheckBox",
          id: "enable_safety_checker",
          label: "Enable Safety Checker",
          default: true,
        },
        {
          type: "DropDown",
          id: "output_format",
          label: "Output Format",
          options: [
            { value: "jpeg", label: "JPEG" },
            { value: "png", label: "PNG" },
          ],
          default: "jpeg",
        },
      ],
      method: "POST",
      authType: "Key",
      documentationAuthLink: "https://fal.ai/models/fal-ai/flux-lora/api",
      getTemplate: (vars) => {
        return {
          prompt: vars.prompt,
          loras: [
            {
              path: vars.path,
              scale: vars.scale,
            },
          ],
          ...vars
        };
      },
      getQuantity(vars) {
        return (vars && vars.num_images) || 1;
      },
      output: {
        path: "request_id",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://queue.fal.run/fal-ai/flux-lora/requests/$REQUEST_ID",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "images",
        },
        authType: "Key",
      },
    },
  ],
};

export default image;
