<template>
  <div :class="['qux-image', cssClass, { 'mdi mdi-image': !hasValidImage }]" @click="onClick">
  </div>
</template>
<style lang="scss">
    @import '../scss/qux-image.scss';
</style>
<script>

import _Base from './_Base.vue'
import Logger from '../core/Logger'

export default {
  name: 'qImage',
  mixins: [_Base],
  data: function () {
      return {
      }
  },
  computed: {
    hasValidImage() {
      let file = this.dataBindingInput;
      return (
        (file && file.name && file.size) ||
        (file && file.url) ||
        (file && file.indexOf && file.indexOf("http") === 0)
      );
    },
  },
  /**
   * FIXME: add here some stuff for image uploads
   */
  mounted () {
     Logger.log(4, 'qImage.mounted()')
  }
}
</script>